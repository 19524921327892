import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Icon from '../components/Icon';
import Button from '../components/Button';
import Hero from '../components/Hero';
import Video from '../components/Video';

const IndexPage = ({ data }) => (
  <Layout title="About">
    <Hero mediaSource="About-image-hero@2x.jpg" textOverlay="About" />
    <Section id="impact-video">
      <Container>
        <Row>
          <Column size={4} className="about__video-text-column">
            <div className="about__video-text-container">
              <h3>Sustainable Futures: Making an Impact</h3>
              <p>
                Learn how employees at the global climate leader, Trane
                Technologies are heading into schools with their Sustainable
                Futures program to empower students like yours to be tomorrow’s
                change-makers.
              </p>
            </div>
          </Column>
          <Column size={8}>
            <Video
              guidSrc="cef13352-fa99-49d9-b472-359c24fb2998"
              className="video-player"
              title="title"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className={'pt-1'}>
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="About-image-GlobalChallenges.jpg" />
          </Column>
          <Column size={6}>
            <Image filename="About-icon-GlobalChallenges.svg" />
            <h2>Tackling Global Challenges with Everyday Actions</h2>
            <p>
              Join Trane Technologies and Discovery Education as we break down
              global sustainability challenges to an individual level and
              empower students to make a positive impact in their world.
              Sustainable Futures provides standards-aligned resources that
              enable students to take real-world action on topics like food
              insecurity, climate change, the environment and sustainability.
              Inspire the next generation of changemakers with hands-on
              activities, STEM career explorations, and interactive in-person
              learning experiences with Trane Technologies volunteers.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <h2>About the Partners </h2>
        </Row>
        <Row className="mt-3">
          <Column size={3} className="about__logo-container">
            <div>
              <Image filename="trane-logo.svg" />
            </div>
          </Column>
          <Column size={9}>
            <p>
              Trane Technologies is a global climate innovator. Through our
              strategic brands Trane and Thermo King, and our environmentally
              responsible portfolio of products and services, we bring efficient
              and sustainable climate solutions to buildings, homes and
              transportation.
            </p>
            <Button to="https://tranetechnologies.com" link target="_blank">
              Visit TraneTechnologies.com <Icon name="externallink" />
            </Button>
          </Column>
        </Row>
        <Row className="mt-3">
          <Column size={3} className="about__logo-container">
            <div style={{ width: '80%' }}>
              <Image filename="de-logo-positive.png" />
            </div>
          </Column>
          <Column size={9}>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in over 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners.
            </p>
            <Button to="https://discoveryeducation.com" link target="_blank">
              Visit DiscoveryEducation.com <Icon name="externallink" />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Row>
        <Column size={7} className="about__quote-text-column">
          <div className="about__quote-text-container">
            <p>
              Environmental education has led to a number of positive impacts,
              from improving academic performance, to enhancing critical
              thinking skills, to developing personal growth and life-building
              skills including confidence, autonomy, and leadership.
            </p>
            <h4>Stanford University</h4>
          </div>
        </Column>
        <Column size={5} className="about__quote-image-container">
          <Image filename="About-image-testimonial.jpg" />
        </Column>
      </Row>
    </Section>
  </Layout>
);

export default IndexPage;
