import React from 'react';
import classNames from 'classnames';
import Image from '../Image';
import './styles.scss';
import PropTypes from 'prop-types';
import { MEDIA_TYPE } from '../../utils/constants';
import Carousel from '../Carousel';
import { Container } from '../Grid';
import Video from '../Video';

const Hero = ({ mediaSource, mediaType, textOverlay, guid, className }) => {
  const isVideo = mediaType && Object.values(MEDIA_TYPE).includes(mediaType);
  const isMultiImage = Array.isArray(mediaSource);

  if (isVideo) {
    return (
      <div className={classNames('hero', className)}>
        <Container>
          <div className='hero__video-background'> 
            <Video className='hero__video' guidSrc={guid}/>
          </div>
        </Container>
      </div>
    );
  } else if (isMultiImage) {
    return (
      <div className={classNames('hero', className)}>
        <Carousel
          headingClassOverride={'mb-0'}
          imageContainerClassOverride={'mb-0'}
          slideClassOverride={'mb-0'}
          settings={{ fade: true }}
          slides={mediaSource}
        />
        {textOverlay}
      </div>
    );
  } else {
    return (
      <div className={classNames('hero mb-1', className)}>
          <Image  filename={mediaSource} className="hero__img" />
          <Container className="hero__container">
            <h1 className="hero__title">{textOverlay}</h1>
          </Container>
      </div>
    );
  }
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {
  /** A path to video or a filename for an image (using the Image Component)
   * or an array of objects for multi image slider
   */
  mediaSource: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  ]),
  /** The type used for the video */
  mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
  /** Text overlay for the image/video. The hero-text-overlay-container-defaults mixin
   * contains the default CSS for a left side text overlay.
   */
  textOverlay: PropTypes.string,
  /** A custom class name */
  classNames: PropTypes.string,
};

export default Hero;
